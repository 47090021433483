@import 'bower_components/sass-material-colors/sass/sass-material-colors';

// $main-color   : material-color('light-green');
$primary-color: #3db5b9;
$main-color   : #ffcc00;
$second-color : #d09012;
$dark         : material-color('grey', '800');
$grey         : material-color('grey');
$green        : material-color('green');
$yellow       : material-color('yellow');
$red          : material-color('red', '200');
$light        : #ffffff;

html {
  position: relative;
  min-height: 100%;
}

* {
  -webkit-transition: none !important;
  transition: none !important;
  text-rendering : optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin         : 0;
  padding        : 0;
  font-family    : 'OatmealPro';
  font-size      : 16px;
  color          : $dark;

  .root {
    padding: 66px 0px 0px 0px;
  }
}
