.signal {
  border: 3px solid #fff;
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 30px;

  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 38px;
    left: 38px;
    width: 116px;
    height: 116px;
    opacity: 0;
  }
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 38px;
    left: 38px;
    width: 116px;
    height: 116px;
    opacity: 0;
  }
}
.lds-ripple {
  position: relative;
}
.lds-ripple div {
  box-sizing: content-box;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1.7s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1.7s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(1) {
  border-color: #ffffff;
}
.lds-ripple div:nth-child(2) {
  border-color: #ffffff;
  -webkit-animation-delay: -0.85s;
  animation-delay: -0.85s;
}
.lds-ripple {
  width: 10px !important;
  height: 10px !important;
  -webkit-transform: translate(-57px, -23px) scale(0.30) translate(47px, 47px);
  transform: translate(-57px, -23px) scale(0.30) translate(47px, 47px);
}