
.btn {
    font-size: 14px;
    border: none;
    border-radius: 0px !important;
    &.btn-default {
        background-color: #f9f9f9;
    }

    &:focus {
        outline: none;
    }

    &.btn-link{

        &:hover{
            color: $main-color;
            text-decoration: underline;
        }

        &:active:focus{
            color: $main-color;
            background: transparent;
            border-color: transparent;
        }
    }

    i {
        font-size: 13px;
    }

}

.btn-outline {

    border-width: 2px;
    border-style: solid;

    background-color: transparent;
    color: inherit;
    transition: all .5s;

    &.btn-primary:hover,
    &.btn-success:hover,
    &.btn-info:hover,
    &.btn-warning:hover,
    &.btn-danger:hover,
    &.btn-primary:focus,
    &.btn-success:focus,
    &.btn-info:focus,
    &.btn-warning:focus,
    &.btn-danger:focus, {
        color: #fff;
    }

    &.btn-primary {
        color: #428bca;

        &.disabled, &[disabled]{
            background: transparent;
            color: #428bca;
        }
    }

    &.btn-success {
        color: #5cb85c;

        &.disabled, &[disabled]{
            background: transparent;
            color: #5cb85c;
        }
    }

    &.btn-info {
        color: #5bc0de;

        &.disabled, &[disabled]{
            background: transparent;
            color: #5bc0de;
        }
    }

    &.btn-warning {
        color: #f0ad4e;

        &.disabled, &[disabled]{
            background: transparent;
            color: #f0ad4e;
        }
    }

    &.btn-danger {
        color: #d9534f;

        &.disabled, &[disabled]{
            background: transparent;
            color: #d9534f;
        }
    }

}
