
.dropdown-menu > li > a {
    position: relative;
    padding: 3px 20px 3px 30px;

    i {
        position: absolute;
        left: 7px;
        top: 5px;
        text-align: center;
        width: 20px;
    }
}
