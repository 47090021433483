@import 'config';
@import 'typography';
@import 'mixins';
@import 'elements/navbar';
@import 'elements/container';
@import 'elements/banner';
@import 'elements/footer';
@import 'elements/dashboard';
@import 'elements/general';
@import 'elements/card';
@import 'elements/table';
@import 'elements/buttons';
@import 'elements/forms';
@import 'elements/modal';
@import 'elements/dropdown';
@import 'elements/switcher';
@import 'elements/tabs';
@import 'elements/phone';
@import 'transitions';
@import 'elements/spinner.scss';
@import 'elements/flex.scss';

@import 'pages/client';
@import 'pages/home';
@import 'pages/auth';
@import 'pages/about';
@import 'pages/share';
@import 'pages/welcome';
@import 'pages/permission';
@import 'pages/profile';
@import 'pages/doorman';

#video-grid {
    position: relative;
    width: 400px;
    height: 400px;
}
  
  #myVideo {
    position: absolute;
    width: 30%;
    height: 40%;
    object-fit: cover;
    z-index: 10;
    bottom: 10px;
    right: 10px;
  }
  #userVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }