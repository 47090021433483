
    .about-panel {

        min-width: 100vw;
        min-height: 100vh;
        padding: 15px;



        .logo {
            max-width: 150px;
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .content {
            background: $light;
            padding: 10px;
            //margin: 20px;
            //animation-fill-mode: forwards;
            height: calc(100vh - 75px);
            width: calc( 100vw - 30px );
            display: flex;
            align-items: flex-start;

            &.center {
                align-items: center;
            }

            .panel-body {
                width: 100%;
            }
        }

        .footer-text {
            position:absolute;
            bottom: 0px;
            left: 15px;
            right: 15px;
            p {
                text-align: center;
            }
        }

        .paint {
            color: $main-color;
        }

        p {
            &.info {
                font-size: 0.7em;
                text-align: center;
            }

            &.data {
                font-weight: bold;
            }
        }

    }
