
.table {
    &.table-pointer {
        tr{
            cursor: pointer
        }
    }

    tr.active td {
        background-color: $main-color !important;
        color: $light;
    }
}

.ng-table th.sortable.sort-desc, .ng-table th.sortable.sort-asc {
    background-color: transparent;
    text-shadow: none;
    color: $red;
}
