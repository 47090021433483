.client {

    .units {
        display: flex;
        overflow: scroll;
        .col {
            max-width: 200px;
            padding: 0 15px;
            border-right: 1px solid #f1f1f1;

            &:first-child {
                padding-left: 0px;
            }
        }

    }

    .card {
        background: #fff;
        box-shadow: none;
        margin-bottom: 15px;
        padding: 15px;
        width: 100%;
        height: auto;
        zoom: inherit;
        cursor: pointer;

        &:hover, &.active {
            background-color: $grey;
            color: $light;
        }

        &.textarea {
            height: auto;

            &:hover {
                background-color: $light;
                color: inherit;
            }
        }

        &.create {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            max-height: 40px;
            font-size: 1em;
            color: $grey;

            &:hover {
                background: $grey;
                color: $light;
            }
        }
    }

    .requirement {

        .title {
            input {
                font-size: 2em;
                border: none;
                padding: 0px;
                text-indent: 0px;
                margin: 0px;
            }
        }

        .description {
            cursor: pointer;
        }
    }
}
