
.form-group {
    label {
        font-weight: normal;;
    }
}

.form-control {
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;
    box-shadow: none;
    border-radius: 0px !important;

    &:focus {
        border-color: #539CDC;
        box-shadow: none;
    }
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    border-color: transparent;
    background-color: #f5f5f5;
    box-shadow: none;
    opacity: 1;
}

.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
    font-weight: 600;
}
