.auth {

    background: $primary-color;
    background: -moz-linear-gradient(180deg, $primary-color 0%, darken($primary-color,5%) 100%);
    background: -webkit-linear-gradient(180deg, $primary-color 0%,darken($primary-color,5%)  100%);
    background: linear-gradient(180deg, $primary-color 0%,darken($primary-color,5%)  100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary-color', endColorstr='$primary-color',GradientType=1 );

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .login-panel {

        min-width: 150px;

        img {
            max-width: 150px;
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .content {
            width: 300px;
            background: $light;
            padding: 20px;
            @include round(15px);
            @include box-shadow(0px, 10px, 50px, rgba(0,0,0,0.2));
        }

        p {
            margin: 15px;
        }

    }

    .or {
        text-align: center;
        margin: 20px 0px;
        position: relative;
        font-size: 0.8em;
        color: $grey;

        &:before, &:after {
            display: block;
            position: absolute;
            top: 12px;
            width: 45%;
            content: " ";
            height: 1px;
            background-color: $grey;
        }

        &:before {
            left: 0px;
        }

        &:after {
            right: 0px;
        }
    }




}

.spinner {
  width: 60px;
  height: 60px;
  margin: 100px auto;
  background-color: #FFF;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
