
.banner {
    position: relative;
    text-align: center;
    margin-bottom: 100px;
    margin-top: -14px;
    padding: 10px 0px;
    height: 100vh;
    @include gradient($main-color,$second-color);

    h1 {
        color: $light;
        font-size: 2em;
        margin-top: 40px;
        margin-bottom: 40px;
    }


    > img {
        position: absolute;
        top: 280px;
        left: 50%;
        width: 900px;
        margin-left: -450px;
    }

    .downloads {
        img {
            margin: 0px 35px;
            width: 200px;
        }
    }
}
