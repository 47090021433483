
.dashboard, .report{

    .filters {
        margin-top: 30px;
    }

    .input-group-date .input-group-btn .btn {
        border: 1px solid #ccc;
    }

    .multiselect-parent .dropdown-menu{
        overflow:scroll !important;
        max-height: 300px !important;
    }
}
