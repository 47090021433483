@font-face {
  font-display: fallback;
  font-family: OatmealPro;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/OatmealPro2-Regular.woff2') format('woff2');
}

body, input, textarea, select, button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
}

h1,h2,h3,h4,h5,h6 {
  font-family: OatmealPro;
  font-weight: normal;
}

h1 {
  font-size: 1.6em;

  &.title {
    display: inline-block;
    margin: 0px;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-right: right
}

.text-light {
  color: $light;
}

a {
  &:hover {
      text-decoration: none;
  }
}

.padding {
    padding: 15px;
}
