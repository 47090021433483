.modal-backdrop {
    background-color: $dark;
}

.modal-header {
    border: none;
}

.modal-content {
    background-color: #fff;
    border: none;
    border-radius: 6px;
    -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15), 0px 0px 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15), 0px 0px 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15), 0px 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.modal {
  .modal-dialog {

    .modal-content {
      -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.2);
      box-shadow: 0 5px 15px rgba(0,0,0,0.2);
      border: none;
      overflow: hidden;

      p {
        margin: 0;
      }

      &.avatar-modal {
        background: #F2F2F2;
        .cropArea {
          overflow: hidden;
          width:450px;
          height:450px;
          margin: 0 auto;
        }
      }
    }

    .modal-body {
      h4 {
        margin: 20px 0px;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: space-around;
      padding: 0px;

      button {
        width: 100%;
        @include round(0px);

        &:first-child {
          @include round-corner('bottom-left', 6px);
        }

        &:last-child {
          @include round-corner('bottom-right', 6px);
        }

        &.btn-link {

          &:hover {
            text-decoration: none;
          }

        }
      }
    }
  }
}
