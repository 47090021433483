.doorman-panel{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 0.6fr 0.5fr 1.9fr; 
  gap: 1em 1em; 
    hr{
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
        border-top: 1px solid #aaaaaa;
    }
    .errored-call{
        background-color: rgb(250, 180, 159);
    }
    .normal-call{
        background-color: whitesmoke;
    }
    .doorman-call{
        display: inline-grid;
        height: 14em;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr ; 
        gap: 0em 0em;
        border-radius: 1em;
        .image-container{
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 1em;
            }

          
        }
        .call-data{
            padding: 1em;
            
        }

        .status{
            .error{
                color:red;
            }
            .intent-container{
                padding-top:1em;
                width: 100%;
                height: 50%;
                text-align: center;
                img{
                    width: 50%;
                    height: 50%;
                    object-fit: cover;
                }
            }
        }
        
    }


}