@keyframes color {
    0% {
        background-color:$primary-color;
    }
    50% {
        background-color: #43A047;
    }
    100% {
        background-color:$primary-color;;
    }
}

.c_icon{
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 70%;
  height: 70%;
  
}
.door_closed{
    background: url(../img/door_closed.svg);
   
   

}
.door_opened{
    background: url(../img/door_opened.svg);
    
}
.button_pressed{
    background: url(../img/button_pressed.svg);
}

.touch_screen{
    background: url(../img/touch_screen.svg);
}

.cart_red{
    background: url(../img/cart_red.svg);
}
.cart_green{
    background: url(../img/cart_green.svg);
}



.home {
    .user-container{
        margin:1em;
        background-color:rgba(255,255,255,0.1);
        border-radius: 10px;
        .user_avatar_list{
            margin: 1em;
        }
        
    }

  .user_td{
    border-top: 2px $primary-color solid !important;
    vertical-align: middle !important;
    background-color: darken($primary-color,5) !important;
    border-bottom: 2px $primary-color solid !important;
  }
  .first_td{
    border-radius: 20px 0px 0px 20px;
  }

  .last_td{
    border-radius: 0px 20px 20px 0px;
  }
    background: $primary-color;

    h3 {
        margin-top: 0px;
    }

    .relative {
        position: relative;
    }

    .absolute {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
    }

    .margin-top {
        margin-top: 30px;
    }

    .keyWrap {
        display: block;
        position: relative;
        width: 100%;
        * {
            color: $light;
        }
    }
    .image-container{
      width: 100%;
      text-align: center;
      margin-bottom: 1em;
    }
    .user_avatar_list{
      width:3em;
      height: 3em;
      border-radius: 100%;
      object-fit: cover;
    }
    .user_avatar_modal{
      width:10em;
      height: 10em;
      border-radius: 100%;
      object-fit: cover;
      margin: auto;
    }

    .input-filter {
        margin-bottom: 15px;
        border: none;
        background-color: lighten($primary-color, 10%)!important;
        color: white!important;
        
    }
    ::placeholder {
        color: $light;
        opacity: 1; /* Firefox */
      }
    .keyTitle {
        height: 30px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: bold;
        color: $main-color;
    }

    .label-control {
        color: $light;
    }

    .text-light {
        color: $light;
    }

    .text-primary {
        color: $main-color;
    }

    .key {
        position: relative;
        height: 60px;
        line-height: 60px;
        margin-bottom: 2px;
        @include round(5px);
        overflow: hidden;

        .trigger-loader {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        &.selected, &:hover {
            background: rgba(255,255,255,0.1);
        }

        .expand {
            padding-left: 10px;
            flex-grow: 1;
        }
        .expand-avatar {
            text-align: center;

            padding-top: 10px;
            padding-bottom: 10px;
            flex-grow: 1;
            .avatar {
                margin-left: auto;
                margin-right: auto;
                vertical-align:top;
                max-height:100%;
                max-height: -webkit-fill-available;
            }
        }

        &.success {
            animation-name: color;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .key-actions-avatar{
            background-color: rgba(0,0,0,0.15);
        }
        .key-actions {
           
            .action {
                width: 50px;
                margin-left: 1px;
                cursor: pointer;
                &:hover {
                    background: rgba(255,255,255,0.2);
                }
            }

            a {
                margin-left: 15px;
            }

            .open {
                text-align: center;
                line-height: 20px;
                margin-top: 10px;
                p {
                    margin: 0px;
                }
            }
        }
    }

    .hours {
        .form-control {
            width: 50px;
            margin: 0px;
        }

        .until {
            width: 100px;
            text-align: center;
            display: inline-block;
        }
    }
}


    .event {
        display:flex;
        min-height: 6em;
        .date {
            position: relative;
            flex:3;
           
           
            .date-container{
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%,-50%);
                background: #ffffff;
                color: #6b6b6b;
                font-size:0.8em;
                margin: 5px;
                text-align: center;
                border-radius: 1em;
                padding: 0.5em;
            }
        }
        .action{
            position: relative;
            text-align: center;
            flex:2;
           
            .line{
                border-width:2px;
                border-style:solid;
                border-color:darken($light,20%);
                width: 2px;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
            }
            .icon-container{
                text-align: center;
                
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%,-50%);
                background-color: #ffffff;
                height: 1.7em;
                width: 1.7em;
                border-radius: 1.5em;
                border-width:4px;
                border-style:solid;
                border-color:darken($light,20%);
                font-size: 2em;
                vertical-align: middle;
                i{
                    position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%,-50%);
                }
            }
            
           
           
           
        }

        .event-content {
            flex:10;
            position: relative;
            padding-top: 1em;
            .event-content-container{
                background: #ffffff;
                color: $dark;
                padding: 5px 10px 5px 10px;
                margin: 5px;
                border-radius: 1em;
                .title {
                    font-size: 16px;
                    margin: 0px;
                }
                .description {
                    white-space: pre-wrap;
                }

            }
            

            

        }

    }



.rzslider{
    .rz-model-value, .rz-bubble.rz-limit {
        color: $light
    }

    .rz-pointer {
        background-color:#fecb02
    }
}
