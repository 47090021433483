.steps {
    display: flex;
}

.step {
    width: 100%;
    align-items: center;
    justify-content: center;

    .group {

    }
}
