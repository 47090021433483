
.navbar {

    background: transparent;
    border: 0px;

    &.active {
        background: $primary-color;
        background: -moz-linear-gradient(180deg, darken($primary-color,5%) 0%,  darken($primary-color,5%) 100%);
        background: -webkit-linear-gradient(180deg, darken($primary-color,5%) 0%, darken($primary-color,5%) 100%);
        background: linear-gradient(180deg, darken($primary-color,5%) 0%, darken($primary-color,5%) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary-color', endColorstr='$primary-color',GradientType=1 );

        @include box-shadow(1px, 0px, 10px, rgba(0,0,0,0.2));
    }

    .navbar-brand {
        line-height: 36px;
       

        &:hover {
            color: $light;
        }
        img {
            width: 3em;
        }
    }

    .navbar-nav {

        .dropdown-menu {

            border: none;

            > li {
                a {
                    background-color: $light;
                    border: none;
                    padding: 3px 15px 3px 15px;

                    &.icon-left {
                        padding: 3px 15px 3px 30px;
                    }

                    &:hover {
                        background-color: $main-color;
                        color: $light;
                    }
                }

            }
        }

        > li {

            > a {
                color: $light;
                letter-spacing: 0.01em;
                white-space: nowrap;
                font-size: 0.9em;
                line-height: 26px;
                margin-top: 6px;
                -webkit-transition: color .2s ease-in-out;
                -moz-transition: color .2s ease-in-out;
                -ms-transition: color .2s ease-in-out;
                -o-transition: color .2s ease-in-out;
                transition: color .2s ease-in-out;
                -webkit-font-smoothing: subpixel-antialiased;
                text-align: center;

                @include round-corner('top-left', $radius: 5px);
                @include round-corner('top-right', $radius: 5px);

                &.active, &.has-hover:hover {
                    color: $yellow;
                    background: transparent !important;

                    &:before {
                        content: " ";
                        position: absolute;
                        display: block;
                        height: 4px;
                        background: $yellow;
                        width: 100%;
                        left: 0px;
                        top: 0px;
                    }
                }

                &:hover, &:focus {
                    color: $yellow;
                }

                &.btn {
                    height: 35px;
                    padding: 0px 20px;
                    margin-top: 14px;
                    border: 0px;
                    background-color: #424242;
                    color: $light;
                    line-height: 35px;

                    &:hover {
                        background-color: #575757;
                    }
                }

                i {
                    font-size: 1.5em !important;
                    margin-right:5px;
                }
            }

            &.open {
                > a, > a:hover {
                    background: $light;
                }
            }
        }
    }

    .user-avatar {
        overflow: hidden;
        width: 30px;
        height: 30px;
        @include round(5px);
        margin-right: 10px;
        margin-left: 5px;
    }

    .corner {
        &.top-left {
            @include round-corner('top-left', $radius: 5px)
        }

        &.top-right {
            @include round-corner('top-right', $radius: 5px)
        }

        &.bottom-left {
            @include round-corner('bottom-left', $radius: 5px)
        }

        &.bottom-right {
            @include round-corner('bottom-right', $radius: 5px)
        }
    }
}
