@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.iphone {
  position: relative;
  background: #202020;
  width: 45%;
  height: 0;
  padding-top: 95%;
  margin: 0 auto;
  border-radius: 3em;
}

.power {
  position: absolute;
  background: #202020;
  width: 13%;
  height: .5%;
  top: -.5%;
  right: 12%;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
}

.lock {
  position: absolute;
  background: #202020;
  width: 1%;
  height: 5%;
  top: 12.5%;
  left: -1%;
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
}

.volume {
  position: absolute;
  background: #202020;
  width: 1%;
  height: 4%;
  top: 23%;
  left: -1%;
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
}

.volume.down{ top: 31%; }

.camera {
  position: absolute;
  top: 4%;
  left: 48.5%;
  background: #3f3f3f;
  width: 3%;
  height: 0;
  padding-top: 3%;
  border-radius: 50%;
}

.speaker {
  position: absolute;
  top: 7%;
  left: 42.5%;
  background: #3f3f3f;
  width: 15%;
  height: 0;
  padding-top: 3%;
  border-radius: 1em;
}

.appleicon {
  padding-top: 25%;
  opacity: 0;
  animation: unhide 5s infinite alternate;
}

.appleicon img{
  width: 100%;
  height: 100%;
}

.text {
  opacity: 0;
  font-size: 1.25rem;
  text-align: center;
  animation: unhide 5s infinite alternate;
}

.screen {
  background: #2f2f2f;
  width: 90%;
  height: 75%;
  position: absolute;
  top: 12.5%;
  left: 5%;
  animation: lighten 5s infinite alternate;
}

.button {
  position: absolute;
  bottom: 2.75%;
  left: 42.5%;
  background: #3f3f3f;
  /* border: 1px solid #3f3f3f; */
  width: 15%;
  height: 0;
  padding-top: 15%;
  border-radius: 50%;
}

.square {
  position: absolute;
  top: 27.5%;
  left: 27.5%;
  border: 2px solid #d7d7d7;
  border-radius: .3em;
  width: 45%;
  height: 45%;
}

@media screen and (min-width: 28em ) {
  body{ font-size: 8px; }
}

@media screen and (min-width: 34em ) {
  body{ font-size: 10px; }
}

@media screen and (min-width: 48em ) {
  body{ font-size: 14px; }
}

@keyframes lighten {
  0%, 45% { background: #2f2f2f; }
  65%, 100% { background: #f7f7f7; }
}

@keyframes unhide {
  0%, 55% { opacity: 0 }
  75%, 100% { opacity: 1 }
}
