
.footer {
    position: absolute;
    background: $dark;
    padding: 0px;
    color: lighten($dark, 30%);
    line-height: 60px;
    bottom: 0;
    width: 100%;
    height: 60px;
    font-size: 0.8em;

    a {
        color: lighten($dark,30%);
        font-size: 2em;
        margin: 0px 10px;

        &:hover {
            color: $light;
            text-decoration: none;
        }
    }

    img {
        width: 100px;
    }
}
