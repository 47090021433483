.alert {
    border-radius: 0px !important;
}
.share {

    @media screen and (-webkit-min-device-pixel-ratio:0) {
        select,
        textarea,
        input {
            font-size: 16px;
        }
    }

    background: #FFFFFF;

    .logo {
        width: 100px;
        margin: 0 auto;
        display: block;
    }

    .toaster .alert {
        position: fixed;
        width: 90%;
        height: 80px;
        top: calc( (100vh - 80px)/2 );
        left: calc( (100vw - 90%)/2 );
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
    }

    .modal-content {
        margin-top: 150px;
        width: 70%;
    }

    .modal-body {
        padding: 0;
    }

    .panel-body {
        padding: 5px;
    }

    .geolocation {
        width: 100%;
    }

    .marker {
        max-width: 50px;
    }

    p {
        &.info {
            font-size: 0.9em;
            text-align: justify;
        }

        &.data {
            //font-weight: bold;
        }

        &.title {
            text-align: center;
        }
    }

    label {
        color: $grey;
        font-size: 0.7em;
    }
    
    .share-panel {

        display        : flex;
        align-items    : flex-start;
        justify-content: center;


        padding        : 0;
        z-index        : 2;
        width          : 100%;
        margin         : 15px;
        width          : calc( 100vw - 30px );

        .content {

            padding-bottom: 90px;
            background    : $light;
            overflow      : hidden;

        

            &.center {
                align-items: center;
            }

            .panel-body {
                width: 100%;
                padding: 15px;
            }

            .cover {
                position: relative;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                margin: 0 auto;
                max-height: 300px;
                height: 150px;
                width: 100%;

                .title {
                    font-size: 1.3em;
                    font-weight: bold;
                    color: $light;
                    position: absolute;
                    bottom: 0px;
                    left: 15px;
                    z-index:99;
                    text-shadow: 0px 1px 2px #000000;
                }

                &::before {
                    content: " ";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
                    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
                }
            }
        }

        #spinner {
            position: relative;
            align-items: center;
            text-align: center;
        }

        .controls {
            margin: 0;

            .alert {
                margin: 0;
                border-radius: 0px !important;
            }

            .input {
                border-radius: 0px;
                margin-bottom: 5px;
            }
        }

        .footer-text {
            position:absolute;
            bottom: 15px;
            left: 15px;
            right: 15px;
            p {
                text-align: center;
            }
        }

        .header-text {
            position:absolute;
            top: 15px;
            left: 15px;
            right: 15px;
        }

        h2 {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        label {

            display: flex;

            h4 {
                color: #999;
                margin-top: 5px;
                margin-bottom: 0;
            }

            h3 {

                margin-top: 0;
                margin-bottom: 0;
            }
        }

    }

    .or {
        text-align: center;
        margin: 10px;
        position: relative;
        font-size: 0.8em;
        color: $grey;

        &:before, &:after {
            display: block;
            position: absolute;
            top: 12px;
            width: 45%;
            content: " ";
            height: 1px;
            background-color: $grey;
        }

        &:before {
            left: 0px;
        }

        &:after {
            right: 0px;
        }
    }

    .spinner {
      width: 60px;
      height: 60px;
      margin: 100px auto;
      background-color: #FFF;

      border-radius: 0;
      -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
      animation: sk-scaleout 1.0s infinite ease-in-out;
    }

    @-webkit-keyframes sk-scaleout {
      0% { -webkit-transform: scale(0) }
      100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
      }
    }

    @keyframes sk-scaleout {
      0% {
        -webkit-transform: scale(0);
        transform: scale(0);
      } 100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
      }
    }
}
