.wow { visibility: hidden; }

.multiselect-parent {
    width: 100%;
}

.invert {
    color: $light;
    &.orange {
        @include gradient($main-color,$second-color);
    }
}

h1,h2,h3,h4,h5,h6 {
    .btn {
       
        line-height: 100%;
        margin-top: -5px;
        margin-left: 20px;
    }
}

.label {
    font-weight: normal;
}

.angular-google-map-container {
    margin-top: 28px;
    height: 385px;
    @include round(5px);
}

.ng-toast__message {
    .alert-success {
        background-color: $green;
        border: none;
        @include box-shadow(0px, 10px, 20px, rgba(0,0,0,0.2));
        margin-top: 55px;
        color: $light;
    }
}

.table-hover {
    tr {
        cursor: pointer;
    }
}

.dropdown-menu {
    > li {
        >a {
            cursor: pointer;
        }
    }
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
.ats-switch
{
  outline:0px !important;
  -webkit-appearance:none;
}

.uib-datepicker-popup {
    button {
        border: none;
    }
}

.pagination {
    margin: 0px;
}

.breadcrumb-wrapper {
    background-color: #f5f5f5;
    margin-bottom: 0px;

    .breadcrumb {
        margin-bottom: 0px;
    }
}

.ng-table th.filter .input-filter {
    border: none;
    background: #f8f8f8;
}

.ng-csv-import {
    .label {
        display: none;
    }
}

.highcharts-credits {
    display: none;
}

*:focus {
    outline:0px !important;
    -webkit-appearance:none;
}